<template>
  <div>
    <div class="card mb-4">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col">
            <h6>DETALLES DEL ABOGADO</h6>
          </div>
        </div>
      </div>
      <div class="card-body px-4">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12" style="background: #80808033; border-radius: 1rem;">
            <div class="col text-center" v-if="lawyer && show_avatar">
              <img v-if="lawyer.avatar" :src="lawyer.avatar" alt="..." class="img-thumbnail">
              <img v-else
                   style="max-width: 200px"
                   src="https://cdn-icons-png.flaticon.com/512/149/149071.png?w=826&t=st=1681779976~exp=1681780576~hmac=b62bef118355188308be83a13bd11e2887b7caffd786ec75d3c56a581d7fdd48"
                   class="img-thumbnail">
              <input type="file" class="form-control mt-2" @change="selectImage">
              <button  class="btn bg-gradient-success mt-4 w-100" @click="uploadAvatar"> update avatar</button>
            </div>
            <div class="table-responsive">
              <table class="table table-borderless table-sm">
                <tbody v-if="lawyer">
                <tr>
                  <th scope="row">Nombre:</th>
                  <th>
                    <input type="text" v-model="lawyer.name" class="form-control">
                  </th>
                </tr>
                <tr>
                  <th scope="row">Email:</th>
                  <th>
                    <input type="text" v-model="lawyer.email" class="form-control">
                  </th>

                </tr>
                <tr>
                  <th scope="row">Phone:</th>
                  <td>
                    <input type="text" v-model="lawyer.phone" class="form-control">
                  </td>

                </tr>
                <tr>
                  <th scope="row">Descripcion:</th>
                  <td>
                    <textarea type="text" class="form-control" rows="10" v-model="lawyer.description"></textarea>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <button class="btn bg-gradient-success w-100 mt-2" @click="updateLawyer">ACTUALIZAR</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12" style="display: none">
            <div class="w-100 text-center">
              <label class="text-center">INGRESAR ESTUDIOS PROFESIONALES</label>
            </div>
            <label>Descripcion del estudio</label>
            <textarea type="text" class="form-control" rows="5" v-model="study.name"></textarea>
            <label>Fechas</label>
            <input type="text" class="form-control" v-model="study.created_at">
            <button class="btn bg-gradient-success w-100 mt-2" @click="addStudy">AGREGAR ESTUDIOS</button>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12" style="display: none">
            <div class="w-100 text-center">
              <label>INGRESAR EXPERIENCIA LABORAL</label>
            </div>
            <label>Descripcion del trabajo</label>
            <textarea type="text" class="form-control" rows="5" v-model="job.name"></textarea>
            <label>Fechas</label>
            <input type="text" class="form-control" v-model="job.created_at">
            <button class="btn bg-gradient-success w-100 mt-2" @click="addJob">AGREGAR EXPERIENCIA</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4" style="display: none">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col">
            <h6>LISTADO DE ESTUDIOS Y EXPERIENCIA</h6>
          </div>
        </div>
      </div>
      <div class="card-body px-4">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="table-responsive">
            <table class="table table-hover table-striped">
              <thead>
              <tr class="text-center">
                <th scope="col" colspan="3" class="text-center">EXPERIENCIA PROFESIONAL</th>
              </tr>
              <tr>
                <th scope="col" class="text-left">Nombre</th>
                <th scope="col" class="text-left">Fecha</th>
                <th scope="col" class="text-left">Opciones</th>
              </tr>
              </thead>
              <tbody v-if="lawyer">
              <tr v-for="(item, index) in lawyer.education" :key="index">
                <td>
                  <div class="limited-width_40">
                    {{ item.title }}
                  </div>
                </td>
                <td>{{ item.created_at }}</td>
                <td>
                  <button class="btn btn-outline-danger btn-sm mb-0" @click="deleteEducation(item.uuid)">
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="table-responsive">
              <table class="table table-hover table-striped">
                <thead>
                <tr class="text-center">
                  <th scope="col" colspan="3" class="text-center">EXPERIENCIA LABORAL</th>
                </tr>
                <tr>
                  <th scope="col" class="text-left">Nombre</th>
                  <th scope="col" class="text-left">Fecha</th>
                  <th scope="col" class="text-left">Opciones</th>
                </tr>
                </thead>
                <tbody v-if="lawyer">
                <tr v-for="(item, index) in lawyer.job" :key="index">
                  <td><div class="limited-width_40">
                    {{ item.title }}
                  </div></td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    <button class="btn btn-outline-danger btn-sm mb-0" @click="deleteJob(item.uuid)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {get, post} from "@/utils/httpRequest";
import Swal from "sweetalert2";
import uploadFile from "@/utils/uploadFile";

export default {
  name: "lawyer-detail",
  data: () => ({
    show_avatar: true,
    user_id: null,
    lawyer: null,
    job:{
      name: '',
      created_at: '',
    },
    study:{
      name: '',
      created_at: '',
    }
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getLawyer()
  },
  methods: {

    async selectImage(e){
      this.url_avatar = e.target.files[0]
    },
    async uploadAvatar(){
      if(this.url_avatar!== ''){
        await uploadFile.upload('lawyer/upload-avatar',{file: this.url_avatar, _id: this.user_id})
        this.show_avatar = false
        this.url_avatar = ''
        await this.getLawyer()
        this.show_avatar = true
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    async getLawyer () {
      const {data} = await get(`lawyer/get-by-id?id=${this.user_id}`)
      this.lawyer = data
    },
    async addStudy(){
      console.log(this.study)
      if(this.study.name !== '' && this.study.created_at !== ''){
        await post("lawyer/add-study",{id: this.user_id, ...this.study })
        this.study.name = ''
        this.study.created_at = ''
        await this.getLawyer()
      }
    },

    async addJob(){
      if(this.job.name !== '' && this.job.created_at !== ''){
        await post("lawyer/add-experience",{id: this.user_id, ...this.job })
        this.job.name = ''
        this.job.created_at = ''
        await this.getLawyer()
      }
    },
    async deleteEducation(education_id) {
      await Swal.fire({
        title: 'Estas seguro?',
        text: "No podras revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#82d616',
        cancelButtonColor: '#ea0606',
        confirmButtonText: 'SI',
        cancelButtonText: 'NO'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post("lawyer/delete-education",{id: this.user_id, education_id:education_id  })
          await this.getLawyer()
        }
      })
    },
    async deleteJob(job_id) {
      await Swal.fire({
        title: 'Estas seguro?',
        text: "No podras revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#82d616',
        cancelButtonColor: '#ea0606',
        confirmButtonText: 'SI',
        cancelButtonText: 'NO'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post("lawyer/delete-experience",{id: this.user_id, job_id:job_id  })
          await this.getLawyer()
        }
      })
    },
    async updateLawyer() {
      await post("lawyer/update", this.lawyer)
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
      await this.getLawyer()
    }
  }
}
</script>
<style scoped>

</style>
